<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
      <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          class="d-lg-block ml-2"
          variant="outline-success"
          @click="$router.go(-1)"
      >
        <BIconArrowLeft />
      </b-button>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          class="d-lg-block mr-2"
          variant="outline-secondary"
          @click="clearCache"
      >
        <feather-icon
            icon="DatabaseIcon"
            size="15"
        />
      </b-button>
      <b-nav-item
          id="chat"
          class="mr-1"
          @click="$router.push({ name: 'messages' })"
      >
        <feather-icon
            :badge="unreadMessageGroups"
            :badge-classes="unreadMessageGroups ? 'bg-danger' : 'bg-info'"
            icon="MessageSquareIcon"
            size="21"
        />
        <b-tooltip
            triggers="hover"
            target="chat"
            title="Üzenetek"
            :delay="{ show: 1000, hide: 50 }"
        />
      </b-nav-item>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userName }}
            </p>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <dark-toggler-mobile />

        <b-dropdown-divider
          class="d-lg-none"
        />

        <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Kijelentkezés</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BButton, BIconArrowLeft, BNavItem, BTooltip,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import DarkTogglerMobile from '@core/layouts/components/app-navbar/components/DarkTogglerMobile.vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
    BIconArrowLeft,
    BNavItem,
    BTooltip,
    // Navbar Components
    DarkToggler,
    DarkTogglerMobile,
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    // eslint-disable-next-line no-undef
    ...mapGetters([
      'userName',
      'userRole',
    ]),
  },
  data(){
    return {
      unreadMessageGroups: 0
    }
  },
  created() {
    this.$root.$on('openChatGroup', () => this.getMessagesCount());
  },
  mounted() {
    this.getMessagesCount()
    this.initMessagesCount()
  },
  methods: {
    initMessagesCount(){
      if(this.$helpers.getLocalStorageItem('userData') && this.$helpers.getLocalStorageItem('token')) {
        this.messagesInterval = setInterval(() => {
          this.getMessagesCount()
        }, 60000)
      }
    },
    getMessagesCount(){
      this.$store.dispatch('getCurrentUser').then(response => {
        this.unreadMessageGroups = response.data.data.unread
      })
    },
    clearCache() {
      this.$bvModal
          .msgBoxConfirm(this.$t('confirm.message.clear-cache'), {
            title: this.$t('modal.title.confirm'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('label.yes'),
            cancelTitle: this.$t('label.no'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              // axios
              this.$store.dispatch('clearCache').then(() => {
                this.$helpers.showSuccessToast(this.$t('text.api.response.cacheCleared'))
              })
            }
          })
    },
    logout() {
      this.$store.dispatch('logout').then(() => {
        clearInterval(this.messagesInterval)
        this.$helpers.logout()
        this.$helpers.showSuccessToast('Sikeres kijelentkezés')
      })
    },
  },
}
</script>
