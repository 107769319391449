export default [
  {
    title: 'menu.home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'menu.reservation',
    route: 'reservation-list',
    icon: 'BookOpenIcon',
    children: [
      {
        title: 'menu.dashboard',
        route: 'reservation-dashboard',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'list',
        },
      },
      {
        title: 'menu.list',
        route: 'reservations',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'list',
        },
      },
      {
        title: 'menu.add',
        route: 'add-reservation',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'create',
        },
      },
      {
        title: 'menu.statistics',
        route: 'reservations-statistics',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'list',
        },
      },
      {
        title: 'menu.day-intervals',
        route: 'reservation-day-interval-list',
        icon: 'ClockIcon',
        children: [
          {
            title: 'menu.list',
            route: 'reservation-day-intervals',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'list',
            },
          },
          {
            title: 'menu.add',
            route: 'add-reservation-day-interval',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'create',
            },
          },
        ],
        meta: {
          'role': 'reservation',
        },
      },
      {
        title: 'menu.events',
        route: 'reservation-event-list',
        icon: 'CalendarIcon',
        children: [
          {
            title: 'menu.list',
            route: 'reservation-events',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'list',
            },
          },
          {
            title: 'menu.add',
            route: 'add-reservation-event',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'create',
            },
          },
        ],
        meta: {
          'role': 'reservation',
        },
      },
      {
        title: 'menu.special-days',
        route: 'reservation-special-day-list',
        icon: 'SunriseIcon',
        children: [
          {
            title: 'menu.list',
            route: 'reservation-special-days',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'list',
            },
          },
          {
            title: 'menu.add',
            route: 'add-reservation-special-day',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'create',
            },
          },
        ],
        meta: {
          'role': 'reservation',
        },
      },
      {
        title: 'menu.fishing-spots',
        route: 'reservation-fishing-spot-list',
        icon: 'FlagIcon',
        children: [
          {
            title: 'menu.list',
            route: 'reservation-fishing-spots',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'list',
            },
          },
          {
            title: 'menu.add',
            route: 'add-reservation-fishing-spot',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'create',
            },
          },
        ],
        meta: {
          'role': 'reservation',
        },
      },
      {
        title: 'menu.maps',
        route: 'reservation-map-list',
        icon: 'MapIcon',
        children: [
          {
            title: 'menu.list',
            route: 'reservation-maps',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'list',
            },
          },
          {
            title: 'menu.add',
            route: 'add-reservation-map',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'create',
            },
          },
        ],
        meta: {
          'role': 'reservation',
        },
      },
      {
        title: 'menu.reference-years',
        route: 'reference-year-list',
        icon: 'HashIcon',
        children: [
          {
            title: 'menu.list',
            route: 'reservation-reference-years',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'list',
            },
          },
          {
            title: 'menu.add-reference-year',
            route: 'add-reservation-reference-year',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'create',
            },
          },
        ],
        meta: {
          'role': 'reservation',
        },
      },
    ],
    meta: {
      role: 'reservation',
    },
  },
  {
    title: 'menu.pricing',
    route: 'pricing-list',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'menu.modify',
        route: 'reservation-pricing',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'edit',
        },
      },
      {
        title: 'menu.pricing-types',
        route: 'reservation-pricing-type-list',
        icon: 'CircleIcon',
        children: [
          {
            title: 'menu.list',
            route: 'reservation-pricing-types',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'list',
            },
          },
          {
            title: 'menu.add',
            route: 'add-reservation-pricing-type',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'create',
            },
          },
        ],
        meta: {
          'role': 'reservation',
        },
      },
      {
        title: 'menu.base-variable-fees',
        route: 'reservation-pricing-base-variable-fees',
        icon: 'CircleIcon',
        children: [
          {
            title: 'menu.list',
            route: 'reservation-pricing-base-variable-fees',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'list',
            },
          },
          {
            title: 'menu.add',
            route: 'add-reservation-pricing-base-variable-fee',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'create',
            },
          },
        ],
        meta: {
          'role': 'reservation',
        },
      },
    ],
    meta: {
      'role': 'reservation',
    },
  },
  {
    title: 'menu.messages',
    route: 'messages',
    icon: 'MessageSquareIcon',
    children: [
      {
        title: 'menu.messages-groups',
        route: 'messages',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'admin',
        },
      },
      {
        title: 'menu.messages-add',
        route: 'add-message',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'admin',
        },
      },
    ],
    meta: {
      role: 'message',
    },
  },
  {
    title: 'menu.users',
    route: 'user-list',
    icon: 'UsersIcon',
    children: [
      {
        title: 'menu.list',
        route: 'users',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'list',
        },
      },
      {
        title: 'menu.add',
        route: 'add-user',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'create',
        },
      },
    ],
    meta: {
      role: 'user',
    },
  },
  {
    title: 'menu.roles',
    route: 'role-list',
    icon: 'LockIcon',
    children: [
      {
        title: 'menu.list',
        route: 'roles',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'list',
        },
      },
      {
        title: 'menu.add',
        route: 'add-role',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'create',
        },
      },
    ],
    meta: {
      role: 'role',
    },
  },
  {
    title: 'menu.articles',
    route: 'article-list',
    icon: 'PenToolIcon',
    children: [
      {
        title: 'menu.cover',
        route: 'articles-cover',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'edit',
        },
      },
      {
        title: 'menu.list',
        route: 'articles',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'list',
        },
      },
      {
        title: 'menu.add',
        route: 'add-article',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'create',
        },
      },
    ],
    meta: {
      role: 'role',
    },
  },
  {
    title: 'menu.document-libraries',
    route: 'document-libraries-list',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'menu.list',
        route: 'document-libraries',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'list',
        },
      },
      {
        title: 'menu.add',
        route: 'add-document-library',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'create',
        },
      },
    ],
    meta: {
      role: 'document_library',
    },
  },
  {
    title: 'menu.record-catches',
    route: 'record-catches-list',
    icon: 'AwardIcon',
    children: [
      {
        title: 'menu.list',
        route: 'record-catches',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'list',
        },
      },
      {
        title: 'menu.overview',
        route: 'record-catch-overview',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'list',
        },
      },
      {
        title: 'menu.add',
        route: 'add-record-catch',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'create',
        },
      },
      {
        title: 'menu.fishermen',
        route: 'fisherman',
        icon: 'UserIcon',
        children: [
          {
            title: 'menu.list',
            route: 'fishermen',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'list',
            },
          },
          {
            title: 'menu.add',
            route: 'add-fisherman',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'create',
            },
          },
        ],
        meta: {
          'role': 'record_catch',
        },
      },
      {
        title: 'menu.fish-categories',
        route: 'fish-categories',
        icon: 'FolderIcon',
        children: [
          {
            title: 'menu.list',
            route: 'fish-categories',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'list',
            },
          },
          {
            title: 'menu.add',
            route: 'add-fish-category',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'create',
            },
          },
        ],
        meta: {
          'role': 'record_catch',
        },
      },
      {
        title: 'menu.fish-types',
        route: 'fish-types',
        icon: 'HashIcon',
        children: [
          {
            title: 'menu.list',
            route: 'fish-types',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'list',
            },
          },
          {
            title: 'menu.add',
            route: 'add-fish-type',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'create',
            },
          },
        ],
        meta: {
          'role': 'record_catch',
        },
      },
      {
        title: 'menu.locations',
        route: 'locations',
        icon: 'FlagIcon',
        children: [
          {
            title: 'menu.list',
            route: 'locations',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'list',
            },
          },
          {
            title: 'menu.add',
            route: 'add-location',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'create',
            },
          },
        ],
        meta: {
          'role': 'record_catch',
        },
      },
      {
        title: 'menu.baits',
        route: 'baits',
        icon: 'FeatherIcon',
        children: [
          {
            title: 'menu.list',
            route: 'baits',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'list',
            },
          },
          {
            title: 'menu.add',
            route: 'add-bait',
            icon: 'CircleIcon',
            meta: {
              'role-method': 'create',
            },
          },
        ],
        meta: {
          'role': 'record_catch',
        },
      },
    ],
    meta: {
      role: 'record_catch',
    },
  },
  {
    title: 'menu.galleries',
    route: 'gallery-list',
    icon: 'ImageIcon',
    children: [
      {
        title: 'menu.list',
        route: 'galleries',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'list',
        },
      },
      {
        title: 'menu.add',
        route: 'add-gallery',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'create',
        },
      },
    ],
    meta: {
      role: 'gallery',
    },
  },
  {
    title: 'menu.newsletters',
    route: 'newsletter-list',
    icon: 'MailIcon',
    children: [
      {
        title: 'menu.list',
        route: 'newsletters',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'list',
        },
      },
      {
        title: 'menu.add',
        route: 'add-newsletter',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'create',
        },
      },
    ],
    meta: {
      role: 'newsletter',
    },
  },
  {
    title: 'menu.meteorology',
    route: 'meteorology-list',
    icon: 'CloudIcon',
    children: [
      {
        title: 'menu.list',
        route: 'meteorology-data',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'list',
        },
      },
      {
        title: 'menu.meteorologyDataSourceList',
        route: 'list-meteorology-datasource',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'list',
        },
      },
      {
        title: 'menu.addMeteorologyDataSource',
        route: 'add-meteorology-datasource',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'create',
        },
      },
    ],
    meta: {
      role: 'meteorology',
    },
  },
  {
    title: 'menu.tags',
    route: 'tag-list',
    icon: 'TagIcon',
    children: [
      {
        title: 'menu.list',
        route: 'tags',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'list',
        },
      },
      {
        title: 'menu.add',
        route: 'add-tag',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'create',
        },
      },
    ],
    meta: {
      role: 'tag',
    },
  },
  {
    title: 'menu.text-templates',
    route: 'text-templates-list',
    icon: 'TypeIcon',
    children: [
      {
        title: 'menu.list',
        route: 'text-templates',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'list',
        },
      },
    ],
    meta: {
      role: 'text_template',
    },
  },
  {
    title: 'menu.email-templates',
    route: 'email-templates-list',
    icon: 'MailIcon',
    children: [
      {
        title: 'menu.list',
        route: 'email-templates',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'list',
        },
      },
    ],
    meta: {
      role: 'email_template',
    },
  },
  {
    title: 'menu.partners',
    route: 'partners-list',
    icon: 'HeartIcon',
    children: [
      {
        title: 'menu.list',
        route: 'partners',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'list',
        },
      },
      {
        title: 'menu.add',
        route: 'add-partner',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'create',
        },
      },
    ],
    meta: {
      role: 'partner',
    },
  },
  {
    title: 'menu.polls',
    route: 'polls-list',
    icon: 'ThumbsUpIcon',
    children: [
      {
        title: 'menu.list',
        route: 'polls',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'list',
        },
      },
      {
        title: 'menu.add',
        route: 'add-poll',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'create',
        },
      },
    ],
    meta: {
      role: 'poll',
    },
  },
  {
    title: 'menu.options',
    route: 'options-list',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'menu.list',
        route: 'options',
        icon: 'CircleIcon',
        meta: {
          'role-method': 'list',
        },
      },
    ],
    meta: {
      role: 'option',
    },
  },
]
